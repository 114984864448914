import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import endpoint from '../../endpointData';
const AdminListUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');

  useEffect(() => {
    
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${endpoint}users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };



  return (
<div>
      <h1 className="text-3xl font-semibold mb-4">Users List</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {users.map((user) => (
            <li key={user.id} className="mb-4">
              <div className="flex justify-between items-center bg-gray-100 border border-gray-200 rounded-md p-4">
                <div>
                  <p className="text-lg font-semibold">{user.email}</p>
                  <p className="text-sm text-gray-600">{user.name}</p>
                </div>
                <Link
                to={`/AdminDashboard/analyzeApplications/${user.id}`}
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2 hover:bg-blue-600 transition duration-300"
              >
                Analyze Application
              </Link>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminListUsers;
