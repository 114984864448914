import React, { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import endpointData from '../endpointData'
const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate=useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8 || password.length > 64) {
      setError('Password must be between 8 and 64 characters long');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setLoading(true);

    try {
      const response = await fetch(`${endpointData}signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        navigate('/login');
        console.log('Account created successfully');
      } else {
        // Handle error
        console.error('Failed to create account');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setLoading(false);
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-100">
      <div className="bg-white p-8 rounded-md shadow-lg w-96">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Sign Up</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-gray-700">Email</label>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-input mt-1 block w-full h-12 rounded-md border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 pl-4" />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-700">Password</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-input mt-1 block w-full h-12 rounded-md border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 pl-4" />
          </div>
          <div>
            <label htmlFor="confirm-password" className="block text-gray-700">Confirm Password</label>
            <input type="password" id="confirm-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-input mt-1 block w-full h-12 rounded-md border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 pl-4" />
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <button type="submit" className="py-3 px-6 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300" disabled={loading}>{loading ? 'Signing up...' : 'Sign Up'}</button>
        </form>
        <p className="mt-4">
        Already have an account?{' '}
        <Link to="/login" className="text-indigo-500 hover:underline">
          Log in
        </Link>
      </p>
      </div>
    </div>
  );
};

export default Signup;
