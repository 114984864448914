import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import money_png from '../assets/referral.png';
import Background_image from '../assets/background.png'
import image_back from '../assets/image.jpeg'
import vugar_image from '../assets/vugar.png'
import rahat_image from '../assets/rahat.png'
import no_commission_image from '../assets/no_comission.png'
import no_guarantor_image from '../assets/no_guarantor.png'
import no_penalty_image from '../assets/no_penalty.png'
import no_impact_image from '../assets/no_impact.png'
import quick_access_image from '../assets/quick_access.png'
import support_image from '../assets/support.png'
const LandingPage = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <Navbar />
      <section
  id="first-section"
  className="h-screen flex justify-center items-center bg-no-repeat bg-center bg-cover"
  style={{ backgroundImage: `url(${image_back})` }}
>
  <div className="w-5/6 mx-auto flex flex-col md:flex-row items-center">
    <div className="text-center md:text-left md:w-1/2 p-4">
      <h1 className="text-5xl font-bold text-black mb-6" style={{color:('#48dcc5')}}>
        WELCOME TO BAYYA
      </h1>
      <div className="text-6xl mb-8 font-bold text-white space-y-2.5">
        <p>MODEST</p>
        <p>INVESTMENT,</p>
        <p>BOUNDLESS</p>
        <p>TREASURE</p>
      </div>
      <button
        onClick={() => scrollToSection('contact-us')}
        className="py-3 px-6  text-black font-semibold rounded-md hover:bg-blue-600 transition duration-300"
        style={{backgroundColor:'#99edc2'}}
      >
        CONTACT US
      </button>
    </div>
    <div className="md:w-1/2 p-4 flex justify-center md:justify-end">
      {/* <img src={image_back} alt="Welcome" className="w-full h-full object-cover" /> */}
    </div>
  </div>
</section>


      <section id="about-us" className="py-20  text-gray-800">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-6">About Us</h2>
            <p className="text-lg leading-relaxed mb-8">
            BAYYA, as a factoring company, provides financial support to small and medium businesses, making their creation possible. A product made by the Supplier is used on a factoring basis, and payment on invoice for the services provided by us is faster. At the same time, the Buyer receives the product, and service fee pays us at the agreed-upon time, so both the Supplier and the Buyer can keep their working capital at the appropriate level and continue their daily business without financial difficulties.
            </p>

          </div>
        </div>
      </section>

      <section id="benefit" className="py-20 bg-gray-800 text-gray-800" style={{backgroundColor:'#007373'}}>
  <div className="container mx-auto px-4">
    <h2 className="text-4xl font-bold mb-12 text-center text-white">
      Benefits of our service
    </h2>
    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
      {[
        { text: "Quick access to up to 90% of required financing", image: `${quick_access_image}`,width: '80px', height: '64px' },
        { text: "No requirement for a guarantor and collateral",  image: `${no_guarantor_image}`,width: '90px', height: '80px' },
        { text: "No commission fees will be charged.",  image: `${no_commission_image}`,width: '56px', height: '64px' },
        { text: "No impact on credit rating because there is no bank and interest",  image: `${no_impact_image}`,width: '64px', height: '64px' },
        { text: "Supporting reliable cooperation between Supplier and Buyer companies",  image: `${support_image}`,width: '80px', height: '46px' },
        { text: "No penalty imposed on the manufacturing company in case of payment delay",  image: `${no_penalty_image}`,width: '64px', height: '64px' }
      ].map((benefit, index) => (
        <li key={index} className="border flex flex-col justify-betweeen border-gray-300 p-6 rounded-lg bg-white shadow-lg text-center">
          <div className="mb-4 flex-1 flex items-center">
            <img src={benefit.image} alt={`Icon ${index + 1}`} className="mx-auto object-fill" style={{width:benefit.width, height:benefit.height}}  />
          </div>
          <h3 className="text-2xl font-semibold text-gray-800 mb-2">{index + 1}</h3>
          <p className="text-gray-700 font-bold">{benefit.text}</p>
        </li>
      ))}
    </ul>
  </div>
</section>


    
        
       <section id="how-it-works" className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-gray-800 mb-12 text-center">
            How it works?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {[
              { title: "Step 1: Sign up", description: "Fill required fields." },
              { title: "Step 2: Meet the requirements", description: "Please provide the required documents and information about your business." },
              { title: "Step 3: Secure the funds.", description: "Retrieve the finances after the approval period has ended." }
            ].map((step, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg text-center hover:bg-blue-100 transition duration-300">
                <div className="flex items-center justify-center h-16 w-16 mx-auto mb-4 rounded-full  text-white text-2xl font-bold" style={{backgroundColor:'#007373'}}>
                  {index + 1}
                </div>
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-700">{step.description}</p>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-12">
            <Link to='/signup' className="py-3 px-6  text-white font-semibold rounded-md  transition duration-300 " style={{backgroundColor:'#2c9d95'}} >
              Sign Up
            </Link>
          </div>
        </div>
      </section>

      <section id="team" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-gray-800 mb-12 text-center">
            Our Team
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12">
            {[
              { name: "Vugar Javadov", title: "CEO", description: "Co-founder and CEO of Neurotime. Previously worked as a software engineer at Morann Games. Prior to that, he was a data scientist at BU Spark. Holds a bachelor's degree in computer science from Boston University.", image: `${vugar_image}` },
              { name: "Rahat Dewan", title: "Advisor", description: "Co-founder and CTO of Buildnow. Has 5 years of experience in Data Science and has been involved in creating AI models assessing $4 billion worth of risk in fintech operations.", image: `${rahat_image}`}
            ].map((member, index) => (
              <div key={index} className="border border-gray-300 p-6 rounded-lg bg-white shadow-lg flex flex-col items-center text-center">
                <img src={member.image} alt={member.name} className="w-40 h-40 rounded-full mb-4" />
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                  {member.name}
                </h3>
                <h4 className="text-xl text-gray-600 mb-4">{member.title}</h4>
                <p className="text-gray-700">{member.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>







    

      <footer className="bg-gray-800 py-8" id="contact-us">
        <div className="container mx-auto px-4 text-center text-gray-400">
          <p className="mb-4">&copy; 2024 BAYYA. All rights reserved.</p>
          <p>Contact us: info@bayya.com | +994 50 123 45 67</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
