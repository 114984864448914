// App.js
import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import AdminLogin from './components/admin/AdminLogin';
import AdminDashboard from './components/admin/AdminDashboard';
import { AuthProvider } from './store/AuthContext';
const App = () => {  
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login_admin" element={<AdminLogin/>} />
          <Route path="/AdminDashboard/*" element={<AdminDashboard/>} />
          <Route path="/signup" element={<Register />} />
          <Route path="/dashboard/*" element={<Dashboard/>} />
        </Routes>
    </Router>
    </AuthProvider>
    
  );
};

export default App;
