import React, { useState, useEffect } from 'react';
import { useNavigate,Link,Routes,Route } from 'react-router-dom';
import AdminListUsers from './AdminListUsers'
import AdminAnalyzeApplications from './AdminAnalyzeApplications'
const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

 
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login_admin");
  };


  const handleUserClick = (userId) => {
    navigate(`/admin/user/${userId}`);
  };

  return (
    <div className="flex h-screen">
      <div className="bg-gray-800 text-white w-1/5 py-4 px-6 flex flex-col justify-between">
        <div>
          <h2 className="text-2xl font-semibold">Dashboard</h2>
          <ul className="mt-4">
            <li className="mb-2">
              <Link
                to="/AdminDashboard/analyzeApplications"
                className="block py-2 px-4 bg-blue-500 text-white rounded-md text-center"
              >
                List of the users
              </Link>
            </li>
            <li className="mb-2">
              
            </li>
          </ul>
        </div>
        <button
          onClick={handleLogout}
          className="py-2 px-4 bg-red-500 text-white rounded-md"
        >
          Logout
        </button>
      </div>

      <div className="w-4/5 py-4 px-6 h-screen overflow-y-auto ">
        <h1 className="text-3xl  mb-4 border-b-2 border-solid">
          <strong>Hello Admin Dashboard</strong>
        </h1>
        <Routes>
          <Route path="/analyzeApplications/*" element={<AdminListUsers />} />
          <Route path="/analyzeApplications/:userId" element={<AdminAnalyzeApplications />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminDashboard;
