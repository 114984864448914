import React, { useState, useEffect } from 'react';
import endpoint from '../endpointData';
import { Link,useNavigate } from 'react-router-dom';
const ApplyForm = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(Array(questions.length).fill(''));
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileError, setFileError] = useState('');
 const navigate=useNavigate()
  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${endpoint}questions`);
      if (response.ok) {
        const data = await response.json();
        setQuestions(data);
      } else {
        console.error('Failed to fetch questions');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      questions.forEach((question, index) => {
        formData.append(`question_${index + 1}`, question.question_text);
        formData.append(`answer_${index + 1}`, answers[index]);
      });
      formData.append('file', file);

      const response = await fetch(`${endpoint}apply`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the Authorization header
        },  
        body: formData,
      });

      if (response.ok) {
        setSuccess(true);
      }else if(response.status==401){
        navigate('/login')
        throw new Error("Unauthored, Please login again")
      } 
      else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile.size / 1024 / 1024; // Size in MB
    if (selectedFile && selectedFile.type === 'application/pdf' && fileSize <= 5) {
      setFile(selectedFile);
      setFileError('');
    } else {
      setFile(null);
      setFileError('Please select a PDF file with a maximum size of 5MB');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className='text-3xl mb-10'>Application Form</h1>
      {loading ? (
        <div className="text-center">
          <svg className="animate-spin h-10 w-10 mr-3 ..." viewBox="0 0 24 24"></svg>
          {/* Replace the above svg code with the Tailwind spinner animation */}
          <p className="mt-2">Loading...</p>
        </div>
      ) : success ? (
        <div>
          <p>Successfully applied</p>
          <Link to='/dashboard/check-applications' className="py-2 px-4 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 transition duration-300" >
            Track Status
          </Link>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {questions.map((question, index) => (
            <div key={index} className="mb-4">
              <label htmlFor={`question_${index + 1}`} className="block text-gray-700">{question.question_text}</label>
              <input
                type="text"
                id={`question_${index + 1}`}
                value={answers[index]}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                className="form-input mt-1 block w-full border border-gray-300 rounded-md px-4 py-2"
                required
              />
            </div>
          ))}
          <div className="mb-4">
            <label htmlFor="file" className="block text-gray-700">File Upload</label>
            <input
              type="file"
              id="file"
              onChange={handleFileChange}    
              className="form-input mt-1 block w-full border border-gray-300 rounded-md px-4 py-2"
              required
            />
            {fileError && <p className="text-red-500">{fileError}</p>}
          </div>
          <button type="submit" className="py-3 px-6 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300">Submit</button>
        </form>
      )}
    </div>
  );
};

export default ApplyForm;
