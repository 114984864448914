import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'
import { useAuth } from '../store/AuthContext';
const Navbar = () => {
  const { isLoggedIn } = useAuth();
  console.log('logged in',isLoggedIn);
  
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="bg-white shadow-lg ">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
        <Link to="/">
          <img src={logo} alt="Company Logo" className="h-20 w-auto inline-block" />
          
        </Link>
          <div className="space-x-4">
          <button onClick={() => scrollToSection('about-us')} className="text-gray-800 cursor-pointer">About Us</button>
            <button onClick={() => scrollToSection('benefit')} className="text-gray-800 cursor-pointer">Benefits of our service</button>
            <button onClick={() => scrollToSection('how-it-works')} className="text-gray-800 cursor-pointer">How it works</button>
          </div>
        </div>
        <div className="space-x-4">
        {isLoggedIn ? (
            <Link to="/dashboard" className="text-gray-800">Dashboard</Link>
          ) : (
            <>
              <Link to="/login" className="text-gray-800">Login</Link>
              <Link to="/signup" className="text-gray-800">Sign Up</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
