import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import endpoint from "../endpointData";

const CheckApplication = () => {
  const [applicationData, setApplicationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchApplicationData();
    }
  }, []);

  const fetchApplicationData = async () => {
    try {
      const response = await fetch(`${endpoint}application`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setApplicationData(data);
      } else {
        console.error("Failed to fetch application data");
      }
    } catch (error) {
      console.error("Error fetching application data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileDownload = async (fileId) => {
    try {
      const response = await fetch(`${endpoint}files/${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `file_${fileId}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error("Failed to download file");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const getStatusMessage = (statusCode) => {
    switch (statusCode) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case -1:
        return "Rejected";
      default:
        return "Unknown";
    }
  };
  console.log(applicationData);

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-semibold mb-4">Your Application</h1>
      {loading ? (
        <div className="flex items-center justify-center">
          <div className="loader"></div>
          <p className="ml-2">Loading...</p>
        </div>
      ) : applicationData ? (
        applicationData.map((application, appIndex) => (
          <div
            key={appIndex}
            className="border border-gray-200 rounded-md p-4 mb-4"
          >
            <p>
              <strong>Application ID:</strong> {application.id}
            </p>
            <p>
              <strong>Application Status:</strong>{" "}
              <span
                className={`text-white px-4 py-2 rounded-md mt-2 mr-2  ${
                  application.status === 1
                    ? "bg-green-500 "
                    : application.status === -1
                    ? "bg-red-500"
                    : "bg-gray-500"
                }`}
              >
                {getStatusMessage(application.status)}
              </span>
              {application.status===-1 && <Link
                    to="/dashboard/apply-form"
                    className="bg-gray-200 text-black px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300"
                  >
                    Apply New Form
                  </Link>}
              {application.notes && (
                <div className="mb-4">
                  <h2 className="text-xl font-semibold mb-2">
                    Rejection Note:
                  </h2>
                  <div className="inline-block border border-red-500 bg-red-100 p-4 rounded-md">
                    <p>{application.notes}</p>
                  </div>
                  
                </div>
              )}
            </p>
            <h2 className="text-xl font-semibold mt-2">Answers:</h2>
            {application.answers.map((answer, index) => (
              <div key={index} className="mb-4">
                <p>
                  <strong>Question {index + 1}:</strong> {answer.question}
                </p>
                <p>
                  <strong>Your Answer:</strong> {answer.answer}
                </p>
              </div>
            ))}
            <h2 className="text-lg font-semibold mt-2">Files:</h2>
            <div className="mb-4">
              <p>{application.file.filename}</p>
              <button
                onClick={() => handleFileDownload(application.file.id)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2 hover:bg-blue-600 transition duration-300"
              >
                Download
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No application data found.</p>
      )}
    </div>
  );
};

export default CheckApplication;
