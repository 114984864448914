import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import endpoint from "../../endpointData";

const AdminAnalyzeApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rejectingApplicationId, setRejectingApplicationId] = useState(null);
  const [note, setNote] = useState("");
  const { userId } = useParams();
  const token = localStorage.getItem("token");

  const fetchUserApplications = async () => {
    console.log('called');
    try {
      const response = await fetch(`${endpoint}application/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setApplications(data);
      } else {
        console.error("Failed to fetch user applications");
      }
    } catch (error) {
      console.error("Error fetching user applications:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserApplications();
  }, []);

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case -1:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  const handleApprove = async (applicationId) => {
    try {
      const response = await fetch(`${endpoint}approve/${applicationId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        fetchUserApplications()
      } else {
        console.error("Failed to approve application");
      }
    } catch (error) {
      console.error("Error approving application:", error);
    }
  };

  const handleRejectClick = (applicationId) => {
    setRejectingApplicationId(applicationId);
  };

  const handleReject = async () => {
    try {
      const response = await fetch(`${endpoint}reject/${rejectingApplicationId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ note }),
      });

      if (response.ok) {
        fetchUserApplications();
        setNote(""); // Clear note input after rejection
        setRejectingApplicationId(null); // Close the note modal or form
      } else {
        console.error("Failed to reject application");
      }
    } catch (error) {
      console.error("Error rejecting application:", error);
    }
  };

  const handleFileDownload = async (fileId) => {
    try {
      const response = await fetch(`${endpoint}files/${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `file_${fileId}.pdf`; 
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Failed to download file');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div>
      <h1 className="text-3xl font-semibold mb-4">
        Applications for User {userId}
      </h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {applications.length === 0 ? (
            <p>No applications found for this user.</p>
          ) : (
            <ul>
              {applications.map((application) => (
                <li key={application.id} className="mb-4">
                  <div className="border border-gray-200 rounded-md p-4">
                    <p className="mb-4">
                      <strong>Application ID:</strong> {application.id}
                    </p>
                    <p>
                      <strong>Status:</strong>{" "}
                      <span
                        className={`text-white px-4 py-2 rounded-md mt-2 mr-2 ${
                          application.status === 1
                            ? "bg-green-500 "
                            : application.status === -1
                            ? "bg-red-500"
                            : "bg-gray-500"
                        }`}
                      >
                        {getStatusText(application.status)}
                      </span>
                    </p>
                    {application.notes && (
                <div className="mb-4 mt-4">
                  <h2 className="text-xl font-semibold mb-2">
                    Rejection Note:
                  </h2>
                  <div className="inline-block border border-red-500 bg-red-100 p-4 rounded-md">
                    <p>{application.notes}</p>
                  </div>
                  
                </div>
              )}
                    <h2 className="text-lg font-semibold mt-2">Answers:</h2>
                    <ul>
                      {application.answers.map((answer, index) => (
                        <li key={index} className="ml-4">
                          <p>
                            <strong>Question {index + 1}:</strong>{" "}
                            {answer.question}
                          </p>
                          <p>
                            <strong>Your Answer:</strong> {answer.answer}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <h2 className="text-lg font-semibold mt-2">Files:</h2>
                    {application.file ? (
                      <div className="mb-4">
                        <p>{application.file.filename}</p>
                        <button
                          onClick={() =>
                            handleFileDownload(application.file.id)
                          }
                          className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2 hover:bg-blue-600 transition duration-300"
                        >
                          Download
                        </button>
                      </div>
                    ) : (
                      <p>No files found.</p>
                    )}
                    {application.status === 0 && (
                      <div>
                        <button
                          onClick={() => handleApprove(application.id)}
                          className="bg-green-500 text-white px-4 py-2 rounded-md mt-2 mr-2 hover:bg-green-600 transition duration-300"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleRejectClick(application.id)}
                          className="bg-red-500 text-white px-4 py-2 rounded-md mt-2 hover:bg-red-600 transition duration-300"
                        >
                          Reject
                        </button>
                        {rejectingApplicationId === application.id && (
                          <div className="mt-4">
                            <textarea
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              placeholder="Enter rejection note..."
                              className="w-full h-32 px-3 py-2 border rounded-md"
                            />
                            <button
                              onClick={handleReject}
                              className="bg-red-500 text-white px-4 py-2 rounded-md mt-2 hover:bg-red-600 transition duration-300"
                            >
                              Confirm Reject
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminAnalyzeApplications;
