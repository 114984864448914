import React, { useState, useEffect } from "react";
import { useNavigate, Link, Routes, Route } from "react-router-dom";
import ApplyForm from "./ApplyForm";
import CheckApplication from "./CheckApplication";

const Dashboard = () => {
  const [userName, setUserName] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const decodedToken = parseJwt(token);
      setUserName(decodedToken.email);
    } else {
      navigate("/login");
    }
  }, [token, navigate]);

  function parseJwt(token) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="flex h-screen">
      <div className="bg-gray-800 text-white w-1/5 py-4 px-6 flex flex-col justify-between">
        <div>
          <h2 className="text-2xl font-semibold">Dashboard</h2>
          <ul className="mt-4">
            <li className="mb-2">
              <Link
                to="/dashboard/apply-form"
                className="block py-2 px-4 bg-blue-500 text-white rounded-md text-center"
              >
                Apply Form
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/dashboard/check-applications"
                className="block py-2 px-4 bg-blue-500 text-white rounded-md text-center"
              >
                Check Applications
              </Link>
            </li>
          </ul>
        </div>
        <button
          onClick={handleLogout}
          className="py-2 px-4 bg-red-500 text-white rounded-md"
        >
          Logout
        </button>
      </div>

      <div className="w-4/5 py-4 px-6 h-screen overflow-y-auto ">
        <h1 className="text-3xl  mb-4 border-b-2 border-solid">
          <strong>Hello, {userName}</strong>
        </h1>
        <Routes>
          <Route path="/apply-form" element={<ApplyForm />} />
          <Route path="/check-applications" element={<CheckApplication />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
